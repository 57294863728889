import React, { useState, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import Layout from '../components/layout'
import ProjectCarousel from '../components/project-carousel'
import Hero from '../components/hero'
import PortraitSection from '../components/portrait-section'
import AboutSection from '../components/about-section'
import FullscreenLoadingSpinner from '../components/fullscreen-loading-spinner'

import { GET_HOMEPAGE } from '../queries/queries'

const HomePage = () => {

    const { data, loading, error } = useQuery(GET_HOMEPAGE)

	return (
		<>
      <FullscreenLoadingSpinner loading={loading} />
      {!loading &&
      <Layout>
          <Hero heroImg={data.homepage.main_image.url}/>
          <PortraitSection 
              portrait={data.homepage.portrait_image.url}
              quoteText={data.homepage.portrait_quote}
          />
          <ProjectCarousel projects={data.projects}/>
          <AboutSection 
              imageAbout={data.homepage.home_page_about_me_picture.url}
              headingAbout={data.homepage.home_page_about_me_title}
              textAbout={data.homepage.home_page_about_me}
          />
      </Layout>}
		</>
	)
}

export default HomePage
