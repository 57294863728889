import React from 'react'

const PortraitSection = ({ portrait, quoteText }) => {

    return (
        <section className="bg-white">
            <div className="container mx-auto px-6 py-8 md:flex md:justify-between md:items-center">
                <div className="grid md:grid-cols-2 md:gap-8">
                    <div>
                        <img className="w-full" src={`${process.env.GATSBY_BACKEND_URL}${portrait}`} />
                    </div>
                    <div className="flex flex-col-reverse font-garamond text-xl lg:text-3xl italic text-right">
                        <div className="pl-12 pt-8 md:pt-0">
                            <p>{quoteText}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortraitSection