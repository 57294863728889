import React from 'react'
import ReactMarkdown from 'react-markdown'

const AboutSection = ({ imageAbout, headingAbout, textAbout }) => {

    return (
        <section id="about" className="bg-white">
            <div className="container mx-auto px-6 py-8 md:flex md:justify-between">
                <div className="grid md:grid-cols-2 md:gap-8">
                    <div className="flex flex-col-reverse">
                        <div className="lg:pr-12 text-justify pb-4">
                            <h2 className="font-garamond text-3xl italic">{headingAbout}</h2>
                            <br></br>
                            <div className="about-text">
                                <ReactMarkdown>
                                  {textAbout}
                                </ReactMarkdown>
                            </div>
                        </div>
                    </div>
                    <div>
                        <img className="w-full" src={`${process.env.GATSBY_BACKEND_URL}${imageAbout}`} />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutSection