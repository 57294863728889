import React from 'react'
import Slider from 'react-slick'
import { Link } from 'gatsby'
import useWindowDimensions from '../hooks/window-dimensions';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const ProjectSlides = ({ projects }) => {

    const dimensions = useWindowDimensions()

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: dimensions.width <= 600 ? 2 : 4,
        slidesToScroll: 3,
        arrows: dimensions.width <= 600 ? false : true
    }

    const projectCarouselMapper = () => {
        const carouselData = projects.map(project => {
            return (
                <Link key={project.id} to={`/projects#${project.project_link_slug}`}>
                    <div className='bg-white rounded-sm overflow-hidden mx-auto px-2'>
                        <div 
                            id="hero-carousel"
                            className="bg-cover bg-no-repeat bg-center flex opacity-100 hover:opacity-75 transition duration-150" 
                            style={{backgroundImage: `url(${process.env.GATSBY_BACKEND_URL}${project.project_cover_image.url})`, height: `${dimensions.width >= 800 ? '600px' : '350px' }`}}>
                        </div>
                        <p className="text-center text-lg font-garamond">{project.project_name}</p>
                    </div>
                </Link>
            )
        })

        return carouselData
    }

    return (
        <Slider {...settings}>
            {projectCarouselMapper()}
        </Slider>
    )
}


const ProjectCarousel = ({ projects }) => {

    return (
        <section className="bg-white">
            <div className="container mx-auto px-6 py-8">
                <ProjectSlides projects={projects} />
            </div>
        </section>
    )
}

export default ProjectCarousel