import React from 'react'

const Hero = ({ heroImg }) => {

    return (
        <section className="bg-white">
            <div className="container mx-auto px-6 py-3 md:flex md:justify-between md:items-center">
                <img className="w-full" src={`${process.env.GATSBY_BACKEND_URL}${heroImg}`}/>
            </div>
        </section>
    )
}

export default Hero